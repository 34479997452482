import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function AdminLecture(admin) {
  const AuthState = useAuthState();
  const { lectureID } = useParams();
  const table = useTable(
    admin ? "lectures-admin" : "lectures-teacher",
    "",
    "",
    "",
    "",
    lectureID
  );

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Lectures | PRODIGY"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          <Link
            to={
              admin
                ? `/admin/lectures-subjects/sessions/${lectureID}/videos`
                : `/teacher/lectures/sessions/${lectureID}/videos`
            }
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Videos
          </Link>

          <Link
            to={
              admin
                ? `/admin/lectures-subjects/sessions/${lectureID}/materials`
                : `/teacher/lectures/sessions/${lectureID}/materials`
            }
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Attachments
          </Link>

          <Link
            to={
              admin
                ? `/admin/lectures-subjects/sessions/${lectureID}/homework`
                : `/teacher/lectures/sessions/${lectureID}/homework`
            }
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Homework
          </Link>

          <Link
            to={
              admin
                ? `/admin/lectures-subjects/sessions/${lectureID}/quiz`
                : `/teacher/lectures/sessions/${lectureID}/quiz`
            }
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Quiz
          </Link>
        </div>
        <hr className="w-3/4 border-[1px] border-secondary" />

        <div className="bottom-btn flex w-full justify-start asm:justify-center">
          <Link
            to={
              admin
                ? `/admin/lectures-subjects/sessions/${lectureID}/add-student`
                : `/teacher/lectures/sessions/${lectureID}/add-student`
            }
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Student
          </Link>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default AdminLecture;
