import HelmetTags from "../../../MainComponents/HelmetTags";
import { Col, Row } from "antd";

import MathImage from "../../../assets/subjects/math.png";
import GeoghraphyImage from "../../../assets/subjects/geoghraphy.png";
import PhysicsImage from "../../../assets/subjects/physics.png";
import FalsfaImage from "../../../assets/subjects/falsfa.png";
import ChemistryImage from "../../../assets/subjects/chemistry.png";
import SpainImage from "../../../assets/subjects/spain.png";
import GermanImage from "../../../assets/subjects/german.png";
import FrenchImage from "../../../assets/subjects/french.png";
import BiologyImage from "../../../assets/subjects/biology.png";
import ItalyImage from "../../../assets/subjects/italy.png";
import GeologyImage from "../../../assets/subjects/geology.png";
import ArabicImage from "../../../assets/subjects/arabic.png";
import HistoryImage from "../../../assets/subjects/history.png";
import EnglishImage from "../../../assets/subjects/english.png";

import { Link } from "react-router-dom";

function StudentSubjects() {
  const subjects = [
    { name: "Math", image: MathImage },
    { name: "الجغرافيا", image: GeoghraphyImage },
    { name: "Physics", image: PhysicsImage },
    { name: "فلسفة وعلم نفس", image: FalsfaImage },
    { name: "كيمياء", image: ChemistryImage },
    { name: "اللغة الاسبانية", image: SpainImage },
    { name: "اللغة ألالمانية", image: GermanImage },
    { name: "Chemistry", image: ChemistryImage },
    { name: "اللغة الفرنسية", image: FrenchImage },
    { name: "أحياء", image: BiologyImage },
    { name: "اللغة الايطالية", image: ItalyImage },
    { name: "Geology", image: GeologyImage },
    { name: "Math", image: MathImage },
    { name: "اللغة العربية", image: ArabicImage },
    { name: "الجيولوجيا", image: GeologyImage },
    { name: "فيزياء", image: PhysicsImage },
    { name: "فيزياء", image: HistoryImage },
    { name: "اللغة الانجليزية", image: EnglishImage },
    { name: "Biology", image: BiologyImage },
  ];
  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="All Subjects | PRODIGY"></HelmetTags>
      <div className="title-wrapper  mt-36">
        <h2
          className={`mb-20 text-center text-5xl font-bold transition-all duration-300 ease-linear `}
        >
          All Subjects
        </h2>
      </div>

      <Row gutter={[25, 25]} justify={"center"} align={"middle"}>
        {subjects?.map((subject) => (
          <Col xs={24} md={12} lg={6} key={Math.random()}>
            <Link to={`/subjects/${subject.name}`}>
              <div className="flex flex-col items-center justify-center gap-6 rounded shadow-lg transition-all duration-700 hover:scale-110 py-10 px-5">
                <img src={subject.image} alt="" />
                <h3 className="font-bold text-3xl">{subject.name}</h3>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default StudentSubjects;
