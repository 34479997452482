import HelmetTags from "../../../MainComponents/HelmetTags";
import { Col, Row } from "antd";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { Link, useParams } from "react-router-dom";

function StudentSubjectTeacher() {
  const { subject } = useParams();

  const [
    getAllTeachersSuccess,
    getAllTeachersErrors,
    getAllTeachersDataLoading,
  ] = useAxios(
    `${process.env.REACT_APP_STUDENT_GET_TEACHERS_API}/${subject}`,
    "GET",
    "GET"
  );

  const allTeacherssData = [
    {
      name: "Omar Ahmed",
      description: "description",
      image:
        "https://api.prodigy-online.com/uploads/profile_picture/1728550533-996.png",
    },
    {
      name: "Omar Ahmed",
      description: "description",
      image:
        "https://api.prodigy-online.com/uploads/profile_picture/1728550533-996.png",
    },
    {
      name: "Omar Ahmed",
      description: "description",
      image:
        "https://api.prodigy-online.com/uploads/profile_picture/1728550533-996.png",
    },
  ];

  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="All Teachers | PRODIGY"></HelmetTags>
      <div className="title-wrapper  mt-36">
        <h2
          className={`mb-20 text-center text-5xl font-bold transition-all duration-300 ease-linear `}
        >
          All Teachers
        </h2>
      </div>

      <Row gutter={[24, 24]} align="middle" justify="center">
        {getAllTeachersSuccess?.teachers?.map((teacher, index) => (
          <Col xs={2} md={12} lg={8} key={index}>
            <Link to={`/subjects/${subject}/${teacher.key}`}>
              <div className="flex flex-col items-center justify-center gap-6 rounded shadow-lg transition-all duration-700 hover:scale-110 py-10 px-5">
                <div className="profile_pic h-60 w-60 overflow-hidden rounded-full">
                  <img
                    className="h-full w-full rounded-full object-cover object-top"
                    src={teacher.image}
                    alt={teacher.name}
                  />
                </div>
                <h3 className="font-bold text-4xl">{teacher?.name}</h3>
                <p className="font-bold">{teacher?.subject}</p>
                <p>{teacher?.description}</p>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default StudentSubjectTeacher;
