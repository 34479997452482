import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";

function Students({ type }) {
  const [academiclevel, setAcademiclevel] = useState("1");

  const url = academiclevel;

  const table = useTable("students", "", "", url, "", url);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20  dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={` Students | PRODIGY`}></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <div className="w-full flex items-center justify-between gap-4">
          <h2 className="flex-1 text-4xl text-left font-bold md:text-center">
            Students
          </h2>
          {/* <Link
            to={`/admin/${
              type === "online" ? "online" : "center"
            }-students/add-student`}
            className={`rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 bg-secondary text-light flex items-center gap-6`}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Student
          </Link> */}
        </div>

        <div className="flex w-full  gap-10 md:justify-center md:flex-col sm:items-center">
          <button
            onClick={() => {
              setAcademiclevel("33");
            }}
            className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 ${
              academiclevel === "33" && "bg-secondary text-light"
            }`}
          >
            Third Preparatory
          </button>

          <button
            onClick={() => {
              setAcademiclevel("1");
            }}
            className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 ${
              academiclevel === "1" && "bg-secondary text-light"
            }`}
          >
            First Secondary
          </button>

          <button
            onClick={() => {
              setAcademiclevel("2");
            }}
            className={` rounded-xl border-2 border-secondary    p-4 font-semibold  transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 ${
              academiclevel === "2" && "bg-secondary text-light"
            }`}
          >
            Second Secondary
          </button>

          <button
            onClick={() => {
              setAcademiclevel("3");
            }}
            className={` rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 ${
              academiclevel === "3" && "bg-secondary text-light"
            }`}
          >
            Third Secondary
          </button>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      {academiclevel ? <div className="h-fit  w-full">{table}</div> : null}
    </section>
  );
}

export default Students;
