import { useRef, useState } from "react";
import useFade from "../../../MainComponents/Hooks/useFade";
import SingleCourse from "../../../MainComponents/SingleCourse";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "../../../MainComponents/GlobalContext.jsx";
import SingleChapter from "../../../MainComponents/SingleChapter";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";

function StudentLectures() {
  const AuthState = useAuthState();
  const { teacherID } = useParams();

  //!---- get all lectures  -------
  const [allLecturesData, allLecturesErrors, allLecturesLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_LECTURES_API}/${teacherID}`,
    "GET",
    "GET"
  );

  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);
  const [isReversed, setIsReversed] = useState(false); // State to toggle the order

  if (allLecturesLoading) {
    return <Loader />;
  }

  if (allLecturesErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{allLecturesErrors}</p>
      </div>
    );
  }

  // Function to handle reversing the array
  const handleReverseOrder = () => {
    setIsReversed((prev) => !prev);
  };

  // Determine the order of lectures
  const lecturesToDisplay = isReversed
    ? [...(allLecturesData?.data || [])].reverse()
    : allLecturesData?.data || [];

  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="Lectures | PRODIGY"></HelmetTags>
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-12 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          Lectures
        </h2>
      </div>

      {/* Button to reverse the order */}
      <div className="flex justify-center mb-12">
        <button
          onClick={handleReverseOrder}
          className="p-5 bg-secondary text-white rounded-3xl "
        >
          Sort{" "}
          <FontAwesomeIcon
            icon={isReversed ? faChevronCircleUp : faChevronCircleDown}
          />
        </button>
      </div>

      <div className="lectures grid-auto-fit">
        {lecturesToDisplay.length === 0 ? (
          <p>No lectures</p>
        ) : (
          lecturesToDisplay.map((Card) => {
            if (Card.is_chapter) {
              return <SingleChapter chapter={Card} key={Card.key} />;
            } else {
              return <SingleCourse lecture={Card} key={Card.key} />;
            }
          })
        )}
      </div>
    </section>
  );
}

export default StudentLectures;
