function Loader() {
  return (
    <div className=" w-full h-screen flex items-center justify-center">
      <div class="typing-indicator">
        <div class="typing-circle"></div>
        <div class="typing-circle"></div>
        <div class="typing-circle"></div>
        <div class="typing-shadow"></div>
        <div class="typing-shadow"></div>
        <div class="typing-shadow"></div>
      </div>
    </div>
  );
}

export default Loader;
