import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";

function SingleChapter({ chapter, admin, reFetch, setReFetch, notSigned }) {
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const { teacherID } = useParams();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Chapter -------

  const [deleteChapterSuccess, deleteChapterErrors, deleteloading] = useAxios(
    process.env.REACT_APP_TEACHER_DELETE_CHAPTER_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteChapter(id) {
    setSubmitDelete({
      flag: "deleteChapter",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteChapterSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteChapterSuccess]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="course group relative h-[390px]  w-[350px] overflow-hidden rounded-[25px] border-2   bg-white shadow-3xl sm:w-[300px]"
    >
      <div className="img relative z-[0] h-full w-full  overflow-hidden rounded-[20px]">
        <img
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? `/admin/lectures-subjects/chapters/${chapter.key}/${teacherID}`
                    : `/lectures/chapters/${chapter.key}`
              }`
            )
          }
          className={`absolute h-full w-full rounded-[20px] object-cover  opacity-80 duration-300 hover:scale-125`}
          src={chapter.img}
          alt={chapter.name}
        />

        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_show_creator")
          )) &&
          admin && (
            <div className="creator pointer-events-none absolute right-12 top-12 flex h-10 w-fit items-center justify-center gap-3 rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
              By {chapter.created_by}
            </div>
          )}
      </div>
      <div className="chapter-details pointer-events-none absolute bottom-0 right-0 z-20 mt-7 flex h-fit w-full translate-y-14 flex-col overflow-hidden bg-red-100/80 p-8 pb-0  transition-all duration-300 ease-in-out group-hover:translate-y-0 md:translate-y-0  ">
        <h3
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? `/admin/lectures-subjects/chapters/${chapter.key}/${teacherID}`
                    : `/lectures/chapters/${chapter.key}`
              }`
            )
          }
          className={`title pointer-events-auto mb-7 w-fit max-w-full cursor-pointer  text-2xl font-bold text-secondary `}
        >
          {chapter?.name?.substring(0, 33)}
        </h3>
        <p className="description text-start text-2xl font-semibold leading-relaxed text-secondary/70">
          {chapter?.description?.substring(0, 70)}...
        </p>

        <div
          className={`visibility-cta  mt-5 flex w-full border-t-2 border-secondary  ${
            admin ? "justify-between" : "justify-center"
          } translate-y-full items-center  py-9 opacity-0  transition-all duration-300 ease-in-out group-hover:translate-y-0 group-hover:opacity-100 md:translate-y-0 md:opacity-100 `}
        >
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_visibility")
            )) &&
            admin && (
              <div className="visibility pointer-events-none  flex h-10 w-fit  items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
                {chapter.visibility === 0 ? "hidden" : "visible"}
              </div>
            )}
          {admin ? (
            <div className="admin-options flex w-full justify-end gap-14">
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_delete")
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="red"
                  cancelText="Cancel"
                  color="#b1e8f4"
                  //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDeleteChapter(chapter.key)}
                  title="Do you really want to delete the chapter?"
                >
                  <button className="pointer-events-auto cursor-pointer rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_edit")
                )) && (
                <Link
                  to={`/admin/lectures-subjects/edit-chapter/${chapter.key}`}
                  className="pointer-events-auto w-24 cursor-pointer rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                >
                  Edit
                </Link>
              )}
            </div>
          ) : (
            <Link
              to={notSigned ? "/signin" : `/lectures/chapters/${chapter.key}`}
              className="cta pointer-events-auto flex items-center justify-center gap-2 rounded-2xl border-2 border-secondary  bg-accent   p-3  text-light transition-all duration-300 ease-in-out hover:gap-4 active:scale-90 "
            >
              View lectures
            </Link>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default SingleChapter;
