import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";
import { Input } from "antd";

function AdminLectures({ admin }) {
  const navigate = useNavigate();
  const { teacherID } = useParams();

  const [academiclevel, setAcademiclevel] = useState(
    localStorage.getItem("academiclevel") || "33"
  );
  const [reFetch, setReFetch] = useState(false);
  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    admin
      ? `${process.env.REACT_APP_ADMIN_LECTURES_TAB_API}/${teacherID}`
      : process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    academiclevel,
    "",
    "",
    reFetch
  );
  useEffect(() => {
    localStorage.setItem("academiclevel", academiclevel);
  }, [academiclevel]);

  return (
    <section
      className={`h-full  w-full  px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | PRODIGY"></HelmetTags>
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Lectures</h2>

        <div className="flex w-full gap-10 md:justify-evenly">
          <button
            onClick={() =>
              navigate(
                admin
                  ? `/admin/lectures-subjects/${teacherID}/add-lecture`
                  : "/teacher/lectures/add-lecture"
              )
            }
            className="mr-4 rounded-xl border-2 border-secondary order-2 bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light active:scale-90"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add lecture
          </button>
          <button
            onClick={() =>
              navigate(
                admin
                  ? `/admin/lectures-subjects/${teacherID}/add-chapter`
                  : "/teacher/lectures/add-chapter"
              )
            }
            className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add a chapter
          </button>
        </div>

        <div className="flex w-full gap-10 md:justify-center md:flex-col sm:items-center">
          <button
            onClick={() => {
              setAcademiclevel("33");
            }}
            className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90 ${
              academiclevel === "33" && "bg-secondary text-light"
            }`}
          >
            Third Preparatory
          </button>
          <button
            onClick={() => {
              setAcademiclevel("1");
            }}
            className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90 ${
              academiclevel === "1" && "bg-secondary text-light"
            }`}
          >
            First Secondary
          </button>

          <button
            onClick={() => {
              setAcademiclevel("2");
            }}
            className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90 ${
              academiclevel === "2" && "bg-secondary text-light"
            }`}
          >
            Second Secondary
          </button>

          <button
            onClick={() => {
              setAcademiclevel("3");
            }}
            className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-white hover:text-secondary active:scale-90 ${
              academiclevel === "3" && "bg-secondary text-light"
            }`}
          >
            Third Secondary
          </button>
        </div>
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {allLecturesData?.data?.filter(
            (lecture) => +lecture?.stage === +academiclevel
          )?.length === 0 ? (
            <p>No lectures</p>
          ) : (
            allLecturesData?.data
              ?.filter((lecture) => +lecture?.stage === +academiclevel)
              ?.map((Card) => {
                if (Card.is_chapter) {
                  return (
                    <SingleChapter
                      reFetch={reFetch}
                      setReFetch={setReFetch}
                      admin
                      chapter={Card}
                      key={Card.key}
                    />
                  );
                } else {
                  return (
                    <SingleCourse
                      reFetch={reFetch}
                      setReFetch={setReFetch}
                      admin
                      lecture={Card}
                      key={Card.key}
                    />
                  );
                }
              })
          )}
        </div>
      )}
    </section>
  );
}

export default AdminLectures;
