import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState } from "react";
import Loader from "../../../../MainComponents/Loader";
import VideoCard from "../../../../MainComponents/VideoCard";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function Videos({ admin }) {
  const AuthState = useAuthState();
  const { lectureID } = useParams();
  const [reFetch, setReFetch] = useState(false);
  const [showVideo, setShowVideo] = useState({
    show: false,
    src: "",
  });

  //!---- get all videos -------
  const [videosSuccess, videosErrors, videosLoading] = useAxios(
    `${process.env.REACT_APP_TEACHER_LECTURE_VIDEOS_API}${lectureID}`,
    "GET",
    lectureID,
    reFetch || lectureID
  );

  return (
    <section
      className={`  h-auto  w-full   px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Videos | PRODIGY"></HelmetTags>

      <div
        onClick={() => setShowVideo((prev) => ({ ...prev, show: false }))}
        className={`absolute inset-0 z-50 flex h-screen w-full origin-bottom items-center justify-center bg-blue-200/40 transition-all duration-300 ease-in-out ${
          showVideo.show === true
            ? "opacity- pointer-events-auto scale-100"
            : "opacity- pointer-events-none scale-0"
        } `}
      >
        <div className="iframe-wrapper aspect-video w-3/4 overflow-hidden rounded-3xl border-0 border-secondary">
          <iframe
            src={showVideo.src}
            width="100%"
            height="100%"
            title="YouTube video player"
            frameBorder="0"
            //style={{ aspectRatio: "16 / 9" }}
            allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="mb-20 flex flex-col items-start gap-20">
        <Link
          to={
            admin
              ? `/admin/lectures-subjects/${lectureID}/add-video`
              : `/teacher/lectures/${lectureID}/add-video`
          }
          className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 "
        >
          <FontAwesomeIcon className="mr-4" icon={faPlus} />
          Add Video
        </Link>

        {videosLoading ? (
          <Loader />
        ) : (
          <div className="videos video-grid-auto-fit w-full ">
            {videosSuccess?.data?.length === 0 ? (
              <p>There are no videos in this lecture</p>
            ) : (
              videosSuccess?.data?.map((video) => (
                <VideoCard
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  setShowVideo={setShowVideo}
                  admin={admin}
                  video={video}
                  key={video.key}
                />
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default Videos;
