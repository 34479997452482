import { useEffect, useReducer, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import HelmetTags from "../../../../MainComponents/HelmetTags";

import CanvasDraw from "react-canvas-draw";

const AdminQuestionCorrectionForm = () => {
  const [content, setContent] = useState("");
  const { studentID, correctionType, questionId } = useParams();
  const [image, setImage] = useState(null);

  const canvasRef = useRef(null);

  const [QeustionInfo, QeustionInfoErrors, QuestionInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_QUESTION_CORRECTION_SINGLE_API}/${questionId}`,
    "GET",
    questionId,
    questionId
  );

  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditChapter": {
        return {
          ...state,
          submitAddEditChapter: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditChapter: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [
    QuestionCorrectionSuccess,
    QuestionCorrectionErrors,
    QuestionCorrectionLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_QUESTION_CORRECTION_API,
    "POST",
    state.submitAddEditChapter.flag,
    state.submitAddEditChapter.dependency,
    state.submitAddEditChapter.data,
    true
  );

  useEffect(() => {
    if (QuestionCorrectionSuccess) {
      navigate(-1);
      reset();
    }
  }, [QuestionCorrectionSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm({ mode: "onTouched" });

  const onSubmit = async (data) => {
    let combinedImageDataUrl = null;

    const finalData = {
      ...data,
      id: questionId,
      comment: content,
      combinedImage: combinedImageDataUrl,
    };

    dispatch({
      type: "setSubmitAddEditChapter",
      payload: {
        flag: "AddEditChapter",
        dependency: !state.submitAddEditChapter.dependency,
        data: finalData,
      },
    });
  };

  useEffect(() => {
    if (QeustionInfo?.data?.draft) setImage(QeustionInfo?.data?.draft);
  }, [QeustionInfo?.data?.draft]);

  const base64ToFile = (dataURL, filename) => {
    const [header, base64] = dataURL.split(",");
    const mime = header.match(/:(.*?);/)[1];
    const binaryString = window.atob(base64);
    const uint8Array = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    return new File([uint8Array], filename, { type: mime });
  };

  const handleSaveAsImage = async () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current.canvasContainer.childNodes[1]; // Get the actual canvas element
      const ctx = canvas.getContext("2d");

      // Create a new image element for the background
      const backgroundImage = new Image();
      backgroundImage.crossOrigin = "anonymous"; // Set CORS attribute if needed
      backgroundImage.src = QeustionInfo?.data?.draft;

      // Wait for the background image to load
      backgroundImage.onload = () => {
        // Create a temporary canvas to draw the background image
        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;

        // Draw the background image on the temporary canvas
        tempCtx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

        // Draw the existing drawing content onto the temporary canvas
        tempCtx.drawImage(canvas, 0, 0);

        // Export the combined canvas content
        const dataURL = tempCanvas.toDataURL("image/png");

        // Convert Data URL to File object
        const file = base64ToFile(dataURL, "drawing-with-background.png");

        // Create a new DataTransfer object
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);

        // Get the file input element and set the file
        setValue("draft", file);
        // const fileInput = document.getElementById("draft");
        // fileInput.files = dataTransfer.files; // Update the file input with the new file
      };
    }
  };

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Correction | PRODIGY"></HelmetTags>
      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="flex   flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
      >
        <input
          className="signin-inputs w-full pl-4    "
          type="file"
          id="draft"
          placeholder="draft"
          name="draft"
          autoComplete="on"
          step="any"
          hidden
          {...register("draft", {
            required: false,
            validate: (value) => !(value[0]?.size > 50000000),
          })}
        />
        <div className=" flex w-full flex-col items-end justify-center gap-6 ">
          <div
            className={`flex w-full flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="degree">
              Degree{" "}
            </label>
            <input
              className="signin-inputs w-full pl-4    "
              type="number"
              id="degree"
              placeholder="Degree"
              name="degree"
              autoComplete="on"
              step="any"
              {...register("degree", {
                required: true,
                maxLength: 40,
                minLength: 1,
              })}
            />
            {errors.degree && (
              <p className="w-full text-end text-[12px] text-white  ">
                {errors.degree.type === "required" && "Please Fill This input"}
                {errors.degree.type === "maxLength" && "Maximum is 40 letters"}
              </p>
            )}

            {
              //!-------server errors -----

              QuestionCorrectionErrors &&
                QuestionCorrectionErrors?.response?.data?.errors?.degree && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {
                      QuestionCorrectionErrors?.response?.data?.errors
                        ?.degree[0]
                    }
                  </p>
                )
            }
          </div>
          <div className="content mb-10 flex w-[900px] flex-col gap-2 xsl:w-[700px] amd:w-[500px]  sm:w-[300px]">
            <h2 className=" mb-3 font-semibold">Comment</h2>
            <CKEditor
              config={{
                toolbar: {
                  items: [
                    "undo",
                    "redo",
                    "|",
                    "imageInsert",
                    "|",
                    "heading",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "link",
                    "blockQuote",
                    "removeFormat",
                    "|",
                    "selectAll",
                    "specialCharacters",
                    "superscript",
                    "subscript",
                    "style",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "|",
                    "mediaEmbed",
                    "insertTable",
                    "|",
                    "fontFamily",
                    "fontColor",
                    "fontBackgroundColor",
                    "fontSize",
                    "highlight",
                    "|",
                    "horizontalLine",
                    "pageBreak",
                    "findAndReplace",
                    "restrictedEditingException",
                    "textPartLanguage",
                    "-",
                  ],
                  shouldNotGroupWhenFull: true,
                },
                language: "en",
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "toggleImageCaption",
                    "imageStyle:inline",
                    "imageStyle:block",
                    "imageStyle:side",
                  ],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "tableCellProperties",
                    "tableProperties",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={content}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
            {errors === "content" && (
              <p className="w-full text-end text-[12px] text-white  ">
                {" "}
                Please Fill This Comment
              </p>
            )}
            {
              //!-------server errors -----

              QuestionCorrectionErrors &&
                QuestionCorrectionErrors?.response?.data?.errors?.content && (
                  <p className="w-full text-end text-[12px] text-white  ">
                    {
                      QuestionCorrectionErrors?.response?.data?.errors
                        ?.content[0]
                    }
                  </p>
                )
            }
          </div>

          {QeustionInfo?.data?.draft && (
            <div
              style={{ position: "relative", width: "100%", height: "auto" }}
            >
              {/* <img
                src={QeustionInfo?.data?.draft}
                // src={Test}
                alt="Uploaded"
                style={{ width: "100%", height: "auto" }}
              /> */}
              <CanvasDraw
                ref={canvasRef}
                brushColor="#000"
                brushRadius={5}
                lazyRadius={0}
                canvasWidth={800}
                canvasHeight={600}
                onChange={handleSaveAsImage}
                // imgSrc={
                //   "https://play-lh.googleusercontent.com/1-hPxafOxdYpYZEOKzNIkSP43HXCNftVJVttoo4ucl7rsMASXW3Xr6GlXURCubE1tA=w3840-h2160-rw"
                // }
                imgSrc={QeustionInfo?.data?.draft}
                style={{
                  width: "100%",
                  height: "600px",
                }}
              />
            </div>
          )}

          <div className="flex items-center gap-10 justify-start w-full ">
            <div className=" flex w-fit  items-center justify-center gap-5 md:w-full">
              <div className="checkbox-wrapper right">
                <div className="cbx">
                  <input
                    id="right"
                    className="signin-inputs w-full"
                    type="radio"
                    name="is_right"
                    // {...register()}
                  />
                  <label htmlFor="right"></label>
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                  </svg>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <defs>
                    <filter id="goo-12">
                      <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="4"
                        result="blur"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                        result="goo-12"
                      ></feColorMatrix>
                      <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                    </filter>
                  </defs>
                </svg>
              </div>

              <label className="w-fit cursor-pointer text-end" htmlFor="right">
                Right
              </label>
            </div>
            <div className=" flex w-fit  items-center justify-center gap-5 md:w-full">
              <div className="checkbox-wrapper wrong">
                <div className="cbx">
                  <input
                    id="wrong"
                    className="signin-inputs w-full"
                    type="radio"
                    name="is_right"
                    // {...register()}
                  />
                  <label htmlFor="Wrong"></label>
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                  </svg>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <defs>
                    <filter id="goo-12">
                      <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="4"
                        result="blur"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                        result="goo-12"
                      ></feColorMatrix>
                      <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                    </filter>
                  </defs>
                </svg>
              </div>

              <label className="w-fit cursor-pointer text-end" htmlFor="wrong">
                Wrong
              </label>
            </div>
          </div>
        </div>

        {/** submit */}
        <button
          disabled={
            !isValid ||
            (QuestionCorrectionLoading && state.submitAddEditChapter.flag)
          }
          className=" submit mt-6 w-full "
          type="submit"
        >
          {QuestionCorrectionLoading && state.submitAddEditChapter.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p>Correct Question</p>
          )}
        </button>
      </form>
    </section>
  );
};

export default AdminQuestionCorrectionForm;
