import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faEye,
  faEyeSlash,
  faSpinner,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

const TeacherInformations = ({ values }) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const { teacherID } = useParams("");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  //!--------- add edit assistant -------

  const [
    EditTeacherInformationSuccess,
    EditTeacherInformationErrors,
    EditTeacherInformationLoading,
  ] = useAxios(
    process.env.REACT_APP_UPDATE_TEACHER_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    setFormValues({ id: teacherID, ...formData });
    setFormFlag("EditAssistantsInformations");
    setFormDependency(true);
  };

  useEffect(() => {
    if (EditTeacherInformationSuccess) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditTeacherInformationSuccess]);

  useEffect(() => {
    if (EditTeacherInformationErrors) {
      toast.error(EditTeacherInformationErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditTeacherInformationErrors]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3x bg-white p-16 shadow-3xl"
    >
      <div className=" flex  w-full flex-col items-start  justify-center  gap-2">
        <label htmlFor="name">Name</label>
        <input
          className="signin-inputs w-full pl-4    "
          type="text"
          id="name"
          placeholder="Name"
          name="name"
          autoComplete="on"
          {...register("name", {
            required: true,
            //pattern: /^[A-Za-z]+$/,
            maxLength: 40,
            minLength: 3,
          })}
        />
        {errors.name && (
          <p className="text-lg text-blue-500  ">
            {errors.name.type === "required" && "Please fill out this field"}
            {errors.name.type === "pattern" && "Only English allowed"}
            {errors.name.type === "maxLength" && "Maximum characters is 40"}
            {errors.name.type === "minLength" &&
              "Please type at least 3 letters"}
          </p>
        )}

        {
          //!-------server errors -----

          EditTeacherInformationErrors &&
            EditTeacherInformationErrors?.response?.data?.errors?.name && (
              <p className="text-lg text-blue-500  ">
                {EditTeacherInformationErrors?.response?.data?.errors?.name[0]}
              </p>
            )
        }
      </div>

      {/** Username and mobile fields */}
      <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
        {/* Username field */}

        <div className="flex w-1/2 flex-col items-start justify-center gap-2 md:order-1 md:w-full">
          <label htmlFor="username">User name</label>
          <input
            className="signin-inputs w-full"
            type="text"
            id="username"
            placeholder="User name"
            name="username"
            autoComplete="on"
            {...register("username", {
              required: true,
              pattern: /^[A-Za-z0-9-_]*$/,
              maxLength: 20,
              minLength: 3,
            })}
          />
          {/* Username validation errors */}
          {errors.username && (
            <p className="text-lg text-blue-500">
              {errors.username.type === "required" &&
                "Please, fill out this field."}
              {errors.username.type === "pattern" && "Only English allowed"}
              {errors.username.type === "maxLength" &&
                "Maximum characters is 20"}
              {errors.username.type === "minLength" &&
                "Please type at least 3 letters"}
            </p>
          )}
          {/* Server errors for username */}
          {EditTeacherInformationErrors &&
            EditTeacherInformationErrors?.response?.data?.errors?.username && (
              <p className="text-lg text-blue-500">
                {
                  EditTeacherInformationErrors?.response?.data?.errors
                    ?.username[0]
                }
              </p>
            )}
        </div>
        {/**  mobile field */}

        <div className="w-1/2 md:order-2 md:w-full">
          <div className="flex w-full flex-col items-start justify-center gap-2">
            {/* Assistant Number */}
            <label htmlFor="mobile">Phone Number</label>
            <div className="relative w-full">
              <input
                className="signin-inputs w-full"
                type="text"
                id="mobile"
                placeholder="Phone Number"
                name="mobile"
                autoComplete="on"
                {...register("mobile", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                })}
              />
              <FontAwesomeIcon
                className="absolute bottom-1/2 right-4 w-5 h-5 translate-y-1/2"
                icon={faWhatsapp}
              />
            </div>
          </div>
          {/* Mobile number validation errors */}
          {errors.mobile && (
            <p className="mt-2 text-lg text-blue-500">
              {errors.mobile.type === "required" && "Please, fill this input"}
              {errors.mobile.type === "pattern" &&
                "Enter your 11-digit phone number"}
              {errors.mobile.type === "maxLength" &&
                "Enter your 11-digit phone number"}
            </p>
          )}
          {/* Server errors for mobile number */}
          {EditTeacherInformationErrors &&
            EditTeacherInformationErrors?.response?.data?.errors?.mobile && (
              <p className="text-lg text-blue-500">
                {
                  EditTeacherInformationErrors?.response?.data?.errors
                    ?.mobile[0]
                }
              </p>
            )}
        </div>
      </div>

      <div
        className={`flex w-full flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
      >
        <label className="w-full truncate" htmlFor="subject">
          Subject
        </label>

        <select
          name="subject"
          id="subject"
          {...register("subject", { required: true })}
        >
          <option value="" disabled selected>
            Select a subject
          </option>

          <option value="اللغة العربية">اللغة العربية</option>
          <option value="الرياضيات">الرياضيات</option>
          <option value="الفيزياء">الفيزياء</option>
          <option value="الكيمياء">الكيمياء</option>
          <option value="احياء">احياء</option>
          <option value="فلسفة و علم">فلسفة و علم</option>
          <option value="تاريخ">تاريخ</option>
          <option value="جغرافيا">جغرافيا</option>
          <option value="علوم متكاملة">علوم متكاملة</option>
          <option value="math">Math</option>
          <option value="physics">Physics</option>
          <option value="chemistry">Chemistry</option>
          <option value="biology">Biology</option>
          <option value="english">English</option>
          <option value="german">German</option>
          <option value="italian">Italian</option>
          <option value="spanish">Spanish</option>
          <option value="geology">Geology</option>
          <option value="integrated science">Integrated Science</option>
        </select>

        {errors.subject && (
          <p className="text-[12px] text-blue-900 ">
            {errors.subject.type === "required" && "Please fill out this field"}
          </p>
        )}
        {
          //!-------server errors -----

          EditTeacherInformationErrors &&
            EditTeacherInformationErrors?.response?.data?.errors?.subject && (
              <p className="w-full  text-[12px] text-blue-900  ">
                {
                  EditTeacherInformationErrors?.response?.data?.errors
                    ?.subject[0]
                }
              </p>
            )
        }
      </div>

      <div className=" flex w-full flex-col items-end justify-center gap-2 ">
        <label className="w-full truncate " htmlFor="description">
          Description
        </label>
        <textarea
          className=" resize-none"
          {...register("description", {
            maxLength: 155,
            minLength: 3,
          })}
          name="description"
          id="description"
          cols="40"
          rows="5"
        ></textarea>
        {errors.description && (
          <p className="text-[12px] text-blue-900 ">
            {errors.description.type === "maxLength" &&
              "The maximum number of characters is 155 characters"}
            {errors.description.type === "minLength" &&
              "Please write at least 3 characters"}
          </p>
        )}
        {
          //!-------server errors -----

          EditTeacherInformationErrors &&
            EditTeacherInformationErrors?.response?.data?.errors
              ?.description && (
              <p className="w-full  text-[12px] text-blue-900  ">
                {
                  EditTeacherInformationErrors?.response?.data?.errors
                    ?.description[0]
                }
              </p>
            )
        }
      </div>

      <button className=" submit mt-6 w-full" type="submit">
        {EditTeacherInformationLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Edit Teacher"
        )}
      </button>
    </form>
  );
};

export default TeacherInformations;
