import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useReducer } from "react";
import { useParams } from "react-router-dom";

const TeacherChangeImage = ({ imgSrc }) => {
  const { teacherID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPhoto": {
        return {
          ...state,
          submitEditPhoto: action.payload,
        };
      }
      case "setUploadedImgSrc": {
        return {
          ...state,
          uploadedImgSrc: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPhoto: {
      flag: "",
      dependency: false,
      data: {},
    },
    uploadedImgSrc: null,
  });

  const [EditPhotoSuccess, EditPhotoErrors, PhotoLoading] = useAxios(
    process.env.REACT_APP_UPDATE_TEACHER_PHOTO_API,
    "POST",
    state.submitEditPhoto.flag,
    state.submitEditPhoto.dependency,
    state.submitEditPhoto.data,
    true
  );
  useEffect(() => {
    if (EditPhotoSuccess) {
      URL.revokeObjectURL(state.uploadedImgSrc);
    }
  }, [EditPhotoSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
  });
  useEffect(() => {
    const subscription = watch((input) => {
      if (input.profile_pic[0]?.name?.length > 0) {
        let imageUrl = URL.createObjectURL(input.profile_pic[0]);
        dispatch({
          type: "setUploadedImgSrc",
          payload: imageUrl,
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);
  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPhoto",
      payload: {
        flag: "SubmitEditPhoto",
        dependency: !state.submitEditPhoto.dependency,
        data: { id: teacherID, profile_pic: data.profile_pic[0] },
      },
    });
  };
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-3xl my-6"
    >
      <h2 className="-mb-3 flex w-full items-center justify-center gap-2  text-[17px] font-bold">
        <FontAwesomeIcon className="mr-2 w-8" icon={faPhotoFilm} />
        <span>Change photo</span>
      </h2>
      {/**  Profile Picture  */}
      <div className="w-full flex flex-col items-center">
        <div className="w-fit ">
          <label htmlFor="profile_pic">
            <div className="profile_pic h-28 aspect-square overflow-hidden rounded-full border-2 border-secondary p-1 cursor-pointer">
              <img
                className="h-full w-full rounded-full object-cover object-top"
                src={state.uploadedImgSrc || imgSrc}
                alt="Assistant Avatar"
              />
            </div>
          </label>
        </div>
        <input
          id="profile_pic"
          hidden
          className="signin-inputs  w-full"
          type="file"
          accept="image/jpg, image/jpeg, image/png, image/webp"
          name="profile_pic"
          {...register("profile_pic", {
            required: true,
            validate: (value) => !(value[0]?.size > 50000000),
          })}
        />

        {errors.profile_pic && (
          <p className="pt-4 text-xs text-blue-500">
            {errors.profile_pic.type === "required" &&
              "This field is required."}
            {errors.profile_pic.type === "validate" && "Max Image Size is 5MB."}
          </p>
        )}
        {
          //!-------Editing server errors -----

          EditPhotoErrors?.response?.data?.errors &&
            EditPhotoErrors?.response?.data?.errors?.profile_pic && (
              <p className="pt-4 text-xs text-blue-500">
                {EditPhotoErrors?.response?.data.errors.profile_pic[0]}
              </p>
            )
        }
      </div>

      {/** Submit Button */}

      <button
        disabled={!isValid || (state.submitEditPhoto.flag && PhotoLoading)}
        className=" submit mt-6 w-full "
        type="submit"
      >
        {state.submitEditPhoto.flag && PhotoLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Edit photo"
        )}
      </button>
    </form>
  );
};

export default TeacherChangeImage;
