import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { Col, Popconfirm, Row } from "antd";
import { useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";

function Teachers() {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const navigate = useNavigate();

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [deleteTeacherSuccess, deleteTeacherErrors, deleteTeacherLoading] =
    useAxios(
      process.env.REACT_APP_DELETE_TEACHER_API,
      "POST",
      submitDelete.flag,
      submitDelete.dependency,
      submitDelete.data,
      true
    );

  const [allTeacherssData, allTeacherssErrors, allTeachersLoading] = useAxios(
    process.env.REACT_APP_ALL_TEACHERS_API,
    "GET",
    "GET",
    reFetch || deleteTeacherSuccess
  );

  function handleDeleteTeacher(id) {
    setSubmitDelete({
      flag: "deleteRequest",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }

  if (allTeachersLoading) return <Loader />;

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="The Teachers | PRODIGY"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Teachers</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("user_add")
          )) && (
          <Link
            to="/admin/Teachers/add-teacher"
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-white hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Teacher
          </Link>
        )}
      </div>
      <div className="flex flex-wrap gap-10 justify-center">
        {allTeacherssData?.data?.map((teacher, index) => (
          <div className="max-w-[344px] w-full" key={index}>
            <div className="flex flex-col items-center justify-center gap-4 text-center">
              <div className="profile_pic h-60 w-60 overflow-hidden rounded-full">
                <img
                  className="h-full w-full rounded-full object-cover object-top"
                  src={teacher.profile_pic}
                  alt={teacher.name}
                />
              </div>
              <h3 className="font-bold text-4xl">{teacher?.name}</h3>
              <p className="font-bold">{teacher?.subject}</p>
              <p>{teacher?.description}</p>
              <div className="m-0 flex  justify-center gap-6">
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("")
                  )) && (
                  <button
                    className="w-60 px-2 rounded-2xl border-2 border-secondary bg-secondary py-4 text-center font-semibold text-light duration-300 hover:bg-white hover:text-secondary active:scale-90"
                    onClick={() =>
                      navigate(`/admin/teachers/edit-teacher/${teacher.key}`)
                    }
                  >
                    Edit
                  </button>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="red"
                    cancelText="Cancel"
                    color="#b1e8f4"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDeleteTeacher(teacher?.key)}
                    title="Do You Want To Delete?"
                  >
                    <button className="w-60 px-2 rounded-2xl border-2 border-accent bg-accent py-4 text-center font-semibold text-light duration-300 hover:bg-white hover:text-accent active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Teachers;
