import {
  faEye,
  faEyeSlash,
  faSpinner,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReducer, useState } from "react";
import { useEffect } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AddNewTeacher = () => {
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);
  const [checkedPermissions, setCheckedPermissions] = useState({});

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditTeacher": {
        return {
          ...state,
          submitAddEditTeacher: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }

      case "setNoPermissions": {
        return {
          ...state,
          noPermissions: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    submitAddEditTeacher: {
      flag: "",
      dependency: false,
      data: {},
    },

    noPermissions: false,
  });

  //!--------- add assistant -------
  const [AddTeacherSuccess, AddTeacherErrors, AddTeacherLoading] = useAxios(
    process.env.REACT_APP_ADD_TEACHER_API,
    "POST",
    state.submitAddEditTeacher.flag,
    state.submitAddEditTeacher.dependency,
    state.submitAddEditTeacher.data,
    true
  );

  useEffect(() => {
    if (AddTeacherSuccess) {
      navigate(-1);
      reset();
    }
  }, [AddTeacherSuccess]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (formData) => {
    const finalData = {
      ...formData,
      profile_pic: formData?.profile_pic[0] || null,
    };
    dispatch({
      type: "setSubmitAddEditTeacher",
      payload: {
        flag: "AddEditTeacher",
        dependency: !state.submitAddEditTeacher.dependency,
        data: finalData,
      },
    });
  };

  return (
    <>
      <h2 className="my-20 text-center text-3xl font-semibold">
        Please fill in the data to add an assistant
      </h2>

      <form
        encType="multipart/form-data"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-3xl"
      >
        {/** Name filed */}
        <div className=" w-full ">
          <div className=" flex  w-full flex-col items-start  justify-center  gap-2">
            <label htmlFor="name">Name</label>
            <input
              className="signin-inputs w-full pl-4    "
              type="text"
              id="name"
              placeholder="Name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 40,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="text-lg text-blue-500  ">
                {errors.name.type === "required" &&
                  "Please fill out this field"}
                {errors.name.type === "pattern" && "Only English allowed"}
                {errors.name.type === "maxLength" && "Maximum characters is 40"}
                {errors.name.type === "minLength" &&
                  "Please type at least 3 letters"}
              </p>
            )}

            {
              //!-------server errors -----

              AddTeacherErrors &&
                AddTeacherErrors?.response?.data?.errors?.name && (
                  <p className="text-lg text-blue-500  ">
                    {AddTeacherErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>
        </div>

        {/** Username and mobile fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
          {/* Username field */}

          <div className="flex w-1/2 flex-col items-start justify-center gap-2 md:order-1 md:w-full">
            <label htmlFor="username">User name</label>
            <input
              className="signin-inputs w-full"
              type="text"
              id="username"
              placeholder="User name"
              name="username"
              autoComplete="on"
              {...register("username", {
                required: true,
                pattern: /^[A-Za-z0-9-_]*$/,
                maxLength: 20,
                minLength: 3,
              })}
            />
            {/* Username validation errors */}
            {errors.username && (
              <p className="text-lg text-blue-500">
                {errors.username.type === "required" &&
                  "Please, fill out this field."}
                {errors.username.type === "pattern" && "Only English allowed"}
                {errors.username.type === "maxLength" &&
                  "Maximum characters is 20"}
                {errors.username.type === "minLength" &&
                  "Please type at least 3 letters"}
              </p>
            )}
            {/* Server errors for username */}
            {AddTeacherErrors &&
              AddTeacherErrors?.response?.data?.errors?.username && (
                <p className="text-lg text-blue-500">
                  {AddTeacherErrors?.response?.data?.errors?.username[0]}
                </p>
              )}
          </div>
          {/**  mobile field */}

          <div className="w-1/2 md:order-2 md:w-full">
            <div className="flex w-full flex-col items-start justify-center gap-2">
              {/* Assistant Number */}
              <label htmlFor="mobile">Phone Number</label>
              <div className="relative w-full">
                <input
                  className="signin-inputs w-full"
                  type="text"
                  id="mobile"
                  placeholder="Phone Number"
                  name="mobile"
                  autoComplete="on"
                  {...register("mobile", {
                    required: true,
                    pattern: /^[\d]{11}/,
                    maxLength: 11,
                  })}
                />
                <FontAwesomeIcon
                  className="absolute bottom-1/2 right-4 w-5 h-5 translate-y-1/2"
                  icon={faWhatsapp}
                />
              </div>
            </div>
            {/* Mobile number validation errors */}
            {errors.mobile && (
              <p className="mt-2 text-lg text-blue-500">
                {errors.mobile.type === "required" && "Please, fill this input"}
                {errors.mobile.type === "pattern" &&
                  "Enter your 11-digit phone number"}
                {errors.mobile.type === "maxLength" &&
                  "Enter your 11-digit phone number"}
              </p>
            )}
            {/* Server errors for mobile number */}
            {AddTeacherErrors &&
              AddTeacherErrors?.response?.data?.errors?.mobile && (
                <p className="text-lg text-blue-500">
                  {AddTeacherErrors?.response?.data?.errors?.mobile[0]}
                </p>
              )}
          </div>
        </div>

        {/**  passwords fields */}
        <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
          {/** Password  */}
          <div className="w-1/2 md:order-1 md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2 ">
              <label htmlFor="password">Password</label>
              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password"
                  className="signin-inputs  w-full"
                  type={`${state.hide ? "text" : "password"}`}
                  placeholder="Password"
                  name="password"
                  autoComplete="on"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />

                <div
                  //!eye icons
                  onClick={() =>
                    dispatch({
                      type: "setHide",
                    })
                  }
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {state.hide ? (
                    <FontAwesomeIcon className="w-5 h-5" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon className="h-5 w-5" icon={faEyeSlash} />
                  )}
                </div>
              </div>
            </div>
            {errors.password && (
              <p className="mt-2 text-lg text-blue-500">
                {errors.password.type === "required" &&
                  "Please fill out this field"}
                {errors.password.type === "maxLength" &&
                  "Maximum 25 characters"}
                {errors.password.type === "minLength" && "على الأقل 6 خانات"}
              </p>
            )}

            {
              //!-------server errors -----

              AddTeacherErrors &&
                AddTeacherErrors?.response?.data?.errors?.password && (
                  <p className="text-lg text-blue-500  ">
                    {AddTeacherErrors?.response?.data?.errors?.password[0]}
                  </p>
                )
            }
          </div>
          {/** Confirm Password  */}
          <div className="w-1/2 md:order-2 md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2 md:order-3 ">
              <label htmlFor="password_confirmation">Confirm password</label>

              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password_confirmation"
                  className="signin-inputs  w-full"
                  type={`${state.hide ? "text" : "password"}`}
                  placeholder="Confirm password"
                  name="password_confirmation"
                  autoComplete="on"
                  {...register("password_confirmation", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />

                <div
                  //!eye icons
                  onClick={() =>
                    dispatch({
                      type: "setHide",
                    })
                  }
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {state.hide ? (
                    <FontAwesomeIcon className="h-5 w-5" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon className="h-5 w-5" icon={faEyeSlash} />
                  )}
                </div>
              </div>
            </div>
            {errors.password_confirmation && (
              <p className="mt-2 text-lg text-blue-500">
                {errors.password_confirmation.type === "required" &&
                  "Please fill out this field"}
                {errors.password_confirmation.type === "maxLength" &&
                  "Maximum characters is 25"}
                {errors.password_confirmation.type === "minLength" &&
                  "Please type at least 6 characters"}
                {errors.password_confirmation.type === "validate" &&
                  "Password does not match"}
              </p>
            )}

            {
              //!-------server errors -----

              AddTeacherErrors &&
                AddTeacherErrors?.response?.data?.errors
                  ?.password_confirmation && (
                  <p className="text-lg text-blue-500  ">
                    {
                      AddTeacherErrors?.response?.data?.errors
                        ?.password_confirmation[0]
                    }
                  </p>
                )
            }
          </div>
        </div>
        <div
          className={`flex w-full flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
        >
          <label className="w-full truncate" htmlFor="subject">
            Subject
          </label>

          <select
            name="subject"
            id="subject"
            {...register("subject", { required: true })}
          >
            <option value="" disabled selected>
              Select a subject
            </option>

            <option value="اللغة العربية">اللغة العربية</option>
            <option value="الرياضيات">الرياضيات</option>
            <option value="الفيزياء">الفيزياء</option>
            <option value="الكيمياء">الكيمياء</option>
            <option value="احياء">احياء</option>
            <option value="فلسفة و علم">فلسفة و علم</option>
            <option value="تاريخ">تاريخ</option>
            <option value="جغرافيا">جغرافيا</option>
            <option value="علوم متكاملة">علوم متكاملة</option>
            <option value="math">Math</option>
            <option value="physics">Physics</option>
            <option value="chemistry">Chemistry</option>
            <option value="biology">Biology</option>
            <option value="english">English</option>
            <option value="german">German</option>
            <option value="italian">Italian</option>
            <option value="spanish">Spanish</option>
            <option value="geology">Geology</option>
            <option value="integrated science">Integrated Science</option>
          </select>

          {errors.subject && (
            <p className="text-[12px] text-blue-900 ">
              {errors.subject.type === "required" &&
                "Please fill out this field"}
            </p>
          )}
          {
            //!-------server errors -----

            AddTeacherErrors &&
              AddTeacherErrors?.response?.data?.errors?.subject && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {AddTeacherErrors?.response?.data?.errors?.subject[0]}
                </p>
              )
          }
        </div>
        {/** User Image  */}
        <div className="flex w-full flex-col items-start gap-2">
          <label className="w-full  " htmlFor="profile_pic">
            Add profile picture
          </label>

          <input
            id="profile_pic"
            className="signin-inputs w-full"
            type="file"
            accept="image/jpg, image/jpeg, image/png, image/webp"
            name="profile_pic"
            {...register("profile_pic", {
              required: true,
              validate: (value) => !(value[0]?.size > 50000000),
            })}
          />

          {errors.profile_pic && (
            <p className="mt-2 text-lg text-blue-500">
              {errors.profile_pic.type === "required" && "Add profile picture"}
              {errors.profile_pic.type === "validate" && "Maximum size is 5 MB"}
            </p>
          )}
          {
            //!-------server errors -----

            AddTeacherErrors &&
              AddTeacherErrors?.response?.data?.errors?.profile_pic && (
                <p className="text-lg text-blue-500  ">
                  {AddTeacherErrors?.response?.data?.errors?.profile_pic[0]}
                </p>
              )
          }
        </div>

        <div className=" flex w-full flex-col items-end justify-center gap-2 ">
          <label className="w-full truncate " htmlFor="description">
            Description
          </label>
          <textarea
            className=" resize-none"
            {...register("description", {
              maxLength: 155,
              minLength: 3,
            })}
            name="description"
            id="description"
            cols="40"
            rows="5"
          ></textarea>
          {errors.description && (
            <p className="text-[12px] text-blue-900 ">
              {errors.description.type === "maxLength" &&
                "The maximum number of characters is 155 characters"}
              {errors.description.type === "minLength" &&
                "Please write at least 3 characters"}
            </p>
          )}
          {
            //!-------server errors -----

            AddTeacherErrors &&
              AddTeacherErrors?.response?.data?.errors?.description && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {AddTeacherErrors?.response?.data?.errors?.description[0]}
                </p>
              )
          }
        </div>

        <button
          disabled={!isValid}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {AddTeacherLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Add Teacher"
          )}
        </button>
      </form>
    </>
  );
};

export default AddNewTeacher;
