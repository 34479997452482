import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import AddEditChooseQuestion from "../QuestionBank/AddEditChooseQuestion.jsx";
import AddEditEssayQuestion from "../QuestionBank/AddEditEssayQuestion.jsx";
import QuestionBankCategory from "../QuestionBank/QuestionBankCategory.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AddSetsForm from "./AddSetsForm.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import Loader from "../../../../MainComponents/Loader.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import SetsTable from "./SetsTable";

function AdminQuiz({ homework, exam, admin }) {
  const { lectureID, examID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditInfo": {
        return {
          ...state,
          submitAddEditInfo: action.payload,
        };
      }
      case "setRefetchForGetInfo": {
        return {
          ...state,
          refetchForGetInfo: action.payload,
        };
      }
      case "setInfo": {
        return {
          ...state,
          info: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditInfo: {
      flag: "",
      dependency: false,
      data: {},
    },
    info: "",
    refetchForGetInfo: false,
  });
  const [values, setValues] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [questionType, setQuestionType] = useState("choose");

  //!--------- get quiz / homework info for checking if there is a quiz or a homework has set previously  -------
  let InfoTeacherApi = homework
    ? `${process.env.REACT_APP_TEACHER_LECTURE_HOMEWORK_API}${lectureID}`
    : exam
      ? `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_ALL_INFO_API}${examID}`
      : `${process.env.REACT_APP_TEACHER_LECTURE_QUIZ_API}${lectureID}`;

  let InfoAdminApi = homework
    ? `${process.env.REACT_APP_ADMIN_LECTURE_HOMEWORK_API}${lectureID}`
    : exam
      ? `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_ALL_INFO_API}${examID}`
      : `${process.env.REACT_APP_ADMIN_LECTURE_QUIZ_API}${lectureID}`;

  const [getInfoSuccess, getInfoErrors, getInfoLoading] = useAxios(
    admin ? InfoAdminApi : InfoTeacherApi,
    "GET",
    "GET",
    refetch
  );

  useEffect(() => {
    if (getInfoSuccess) {
      if (homework) {
        const { title, visibility, success_rate } =
          getInfoSuccess?.data?.homework_info[0];
        setValues({
          title,
          success_rate,
          visibility: visibility === 1 ? true : false,
        });
      } else {
        const { duration, visibility, success_rate } =
          getInfoSuccess?.data?.quiz_info[0];
        setValues({
          duration,
          visibility: visibility === 1 ? true : false,
          success_rate,
        });
      }
    }
  }, [getInfoSuccess]);

  //!--------- add edit quiz / homework info  -------

  let addEditTeacherInfoApi =
    homework && getInfoSuccess
      ? process.env.REACT_APP_TEACHER_EDIT_HOMEWORK_API
      : homework
        ? process.env.REACT_APP_TEACHER_ADD_HOMEWORK_API
        : getInfoSuccess
          ? process.env.REACT_APP_TEACHER_EDIT_QUIZ_API
          : process.env.REACT_APP_TEACHER_ADD_QUIZ_API;

  let addEditAdminInfoApi =
    homework && getInfoSuccess
      ? process.env.REACT_APP_ADMIN_EDIT_HOMEWORK_API
      : homework
        ? process.env.REACT_APP_ADMIN_ADD_HOMEWORK_API
        : getInfoSuccess
          ? process.env.REACT_APP_ADMIN_EDIT_QUIZ_API
          : process.env.REACT_APP_ADMIN_ADD_QUIZ_API;

  const [AddEditInfoSuccess, AddEditInfoErrors, AddEditInfoLoading] = useAxios(
    admin ? addEditAdminInfoApi : addEditTeacherInfoApi,
    "POST",
    state.submitAddEditInfo.flag,
    state.submitAddEditInfo.dependency,
    state.submitAddEditInfo.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values,
  });

  useEffect(() => {
    //!---add actions ----

    if (AddEditInfoSuccess) {
      setRefetch(!refetch);
    }
  }, [AddEditInfoSuccess]);

  function onSubmitAddEditInfo(data) {
    if (homework) {
      const { title, visibility, success_rate } = data;
      let finalData = getInfoSuccess
        ? {
            homework_id: getInfoSuccess?.data?.homework_info[0].key,
            title,
            visibility: visibility === true ? 1 : 0,
            success_rate,
          }
        : { lecture_id: lectureID, ...data };

      dispatch({
        type: "setSubmitAddEditInfo",
        payload: {
          flag: "Quiz",
          dependency: !state.submitAddEditInfo.dependency,
          data: finalData,
        },
      });
    } else {
      const { duration, visibility, success_rate } = data;
      let finalData = getInfoSuccess
        ? {
            quiz_id: getInfoSuccess?.data?.quiz_info[0]?.key,
            duration: duration,
            visibility: visibility === true ? 1 : 0,
            success_rate,
          }
        : { lecture_id: lectureID, ...data };
      dispatch({
        type: "setSubmitAddEditInfo",
        payload: {
          flag: "Quiz",
          dependency: !state.submitAddEditInfo.dependency,
          data: finalData,
        },
      });
    }
  }

  if (getInfoLoading) {
    return <Loader />;
  }

  return (
    <section className="flex h-auto w-full flex-col gap-20 overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags
        title={`${homework ? "Homework" : "Exam"} | PRODIGY`}
      ></HelmetTags>
      {!exam && (
        <div className="add-quiz-homework flex w-full flex-col items-center gap-14 md:items-center">
          <h2 className="w-full  text-3xl font-semibold">
            {getInfoSuccess && homework
              ? "Edit Homework"
              : getInfoSuccess
                ? "Edit Quiz"
                : homework
                  ? "Add Homework"
                  : "Add Exam"}
          </h2>
          {/**
           * //!--- add exam duration or homework name -------
           */}
          {/* {homework && ( */}
          <form
            method="post"
            noValidate
            onSubmit={handleSubmit(onSubmitAddEditInfo)}
            className="flex w-3/4  flex-col  justify-start gap-10 rounded-3xl bg-white p-16 shadow-3xl lg:w-full"
          >
            {homework ? (
              <>
                <div className="title flex w-full flex-col items-start  justify-center  gap-2">
                  <label htmlFor="title">Name</label>
                  <input
                    className="signin-inputs w-full pl-4  mt-2"
                    type="text"
                    id="title"
                    placeholder="Name"
                    title="title"
                    autoComplete="on"
                    {...register("title", {
                      required: true,
                      //pattern: /^[A-Za-z]+$/,
                      maxLength: 255,
                      minLength: 3,
                    })}
                  />
                  {errors.title && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {errors.title.type === "required" &&
                        "Please fill out this field"}
                      {errors.title.type === "pattern" &&
                        "يسمح فقط باستخدام الحروف الإنجليزية"}
                      {errors.title.type === "maxLength" &&
                        "أقصي عدد للحروف هو ٢٢٥ حرف"}
                      {errors.title.type === "minLength" &&
                        "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                    </p>
                  )}
                </div>
                <div className="flex w-full flex-col items-start  justify-center  gap-2">
                  <label htmlFor="success_rate">Success Rate</label>
                  <input
                    className="signin-inputs w-full pl-4 mt-2"
                    type="number"
                    id="success_rate"
                    placeholder="Success Rate"
                    title="success_rate"
                    autoComplete="on"
                    {...register("success_rate", {
                      required: true,
                      //pattern: /^[A-Za-z]+$/,
                      minLength: 1,
                    })}
                  />
                  {errors.success_rate && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {errors?.success_rate.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                </div>

                {getInfoSuccess?.is_found === 1 && (
                  <div className=" visibility flex w-full flex-col  justify-center gap-2 md:w-full ">
                    <div className="flex w-full items-center justify-end gap-10">
                      <label className="visibility-switch">
                        <input
                          className=""
                          id="visibility"
                          name="visibility"
                          {...register("visibility", {
                            required: false,
                          })}
                          type="checkbox"
                        />
                        <span className="visibility-slider">
                          <svg
                            className="slider-icon"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            role="presentation"
                          >
                            <path fill="none" d="m4 16.5 8 8 16-16"></path>
                          </svg>
                        </span>
                      </label>
                      <div>
                        <label
                          className="w-full cursor-pointer truncate"
                          htmlFor="visibility"
                        >
                          Prerequisite
                        </label>
                      </div>
                    </div>

                    {errors.visibility && (
                      <p className="text-[12px] text-blue-900 ">
                        {errors.visibility.type === "required" &&
                          "Please fill out this field"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      AddEditInfoErrors &&
                        AddEditInfoErrors?.response?.data?.errors
                          ?.visibility && (
                          <p className="w-full  text-[12px] text-blue-900  ">
                            {
                              AddEditInfoErrors?.response?.data?.errors
                                ?.visibility[0]
                            }
                          </p>
                        )
                    }
                  </div>
                )}

                {
                  //!-------server errors -----

                  AddEditInfoErrors &&
                    AddEditInfoErrors?.response?.data?.errors?.title && (
                      <p className="w-full  text-[12px] text-blue-900  ">
                        {AddEditInfoErrors?.response?.data?.errors?.title[0]}
                      </p>
                    )
                }
              </>
            ) : (
              <div className="flex  w-full items-center justify-evenly gap-16 flex-col-reverse md:gap-10 ">
                {getInfoSuccess?.is_found === 1 && (
                  <div className=" visibility flex w-full flex-col items-start justify-center gap-2 lg:w-full ">
                    <div className="flex w-full items-center justify-end gap-10">
                      <label className="visibility-switch">
                        <input
                          className=""
                          id="visibility"
                          name="visibility"
                          {...register("visibility", {
                            required: false,
                          })}
                          type="checkbox"
                        />
                        <span className="visibility-slider">
                          <svg
                            className="slider-icon"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            role="presentation"
                          >
                            <path fill="none" d="m4 16.5 8 8 16-16"></path>
                          </svg>
                        </span>
                      </label>
                      <div>
                        <label
                          className="w-full cursor-pointer truncate"
                          htmlFor="visibility"
                        >
                          Prerequisite
                        </label>
                      </div>
                    </div>

                    {errors.visibility && (
                      <p className="text-[12px] text-blue-900 ">
                        {errors.visibility.type === "required" &&
                          "Please fill out this field"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      AddEditInfoErrors &&
                        AddEditInfoErrors?.response?.data?.errors
                          ?.visibility && (
                          <p className="w-full  text-[12px] text-blue-900  ">
                            {
                              AddEditInfoErrors?.response?.data?.errors
                                ?.visibility[0]
                            }
                          </p>
                        )
                    }
                  </div>
                )}
                <div
                  //!--duration--
                  className=" duration flex w-full flex-col justify-center gap-2 lg:w-full"
                >
                  <label className="w-fit truncate" htmlFor="duration">
                    Duration of the exam in minutes
                  </label>
                  <input
                    className="signin-inputs w-full "
                    type="number"
                    min={1}
                    id="seconds"
                    max={59}
                    placeholder="min"
                    name="seconds"
                    autoComplete="on"
                    {...register("duration", {
                      valueAsNumber: true,
                      min: 1,
                      required: true,
                    })}
                  />

                  {errors.duration && (
                    <>
                      <p className="text-[12px] text-blue-900 ">
                        {errors.duration?.type === "required" &&
                          "Please fill out this field"}
                        {errors.duration?.type === "min" &&
                          "من فضلك ادخل رقم اكبر من واحد"}
                      </p>
                    </>
                  )}
                  {
                    //!-------server errors -----

                    AddEditInfoErrors &&
                      AddEditInfoErrors?.response?.data?.errors?.duration && (
                        <p className="w-full  text-[12px] text-blue-900  ">
                          {
                            AddEditInfoErrors?.response?.data?.errors
                              ?.duration[0]
                          }
                        </p>
                      )
                  }
                </div>
                <div
                  //!--duration--
                  className="success_rate flex w-full   flex-col justify-center gap-2 lg:w-full"
                >
                  <label className="w-fit truncate" htmlFor="success_rate">
                    Success Rate
                  </label>
                  <input
                    className="signin-inputs w-full "
                    type="number"
                    min={1}
                    id="success_rate"
                    max={100}
                    placeholder="%"
                    name="success_rate"
                    autoComplete="on"
                    {...register("success_rate", {
                      valueAsNumber: true,
                      min: 1,
                      required: true,
                    })}
                  />

                  {errors.success_rate && (
                    <>
                      <p className="text-[12px] text-blue-900 ">
                        {errors.success_rate?.type === "required" &&
                          "Please fill out this field"}
                      </p>
                    </>
                  )}
                  {
                    //!-------server errors -----

                    AddEditInfoErrors &&
                      AddEditInfoErrors?.response?.data?.errors?.duration && (
                        <p className="w-full  text-[12px] text-blue-900  ">
                          {
                            AddEditInfoErrors?.response?.data?.errors
                              ?.duration[0]
                          }
                        </p>
                      )
                  }
                </div>
              </div>
            )}

            {/** submit */}

            <button
              disabled={
                !isValid || (AddEditInfoLoading && state.submitAddEditInfo.flag)
              }
              className=" submit mt-6 w-full "
              type="submit"
            >
              {AddEditInfoLoading && state.submitAddEditInfo.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <p>
                  {getInfoSuccess?.is_found === 1 && homework
                    ? "Edit Homework"
                    : getInfoSuccess?.is_found === 1
                      ? "Edit Exam"
                      : homework
                        ? "Add Homework"
                        : "Add Exam"}
                </p>
              )}
            </button>
          </form>
          {/* )} */}
        </div>
      )}

      {exam || getInfoSuccess?.is_found === 1 ? (
        <>
          <div className="category-questions flex w-full flex-col gap-14 md:items-center">
            <h2 className=" text-3xl font-semibold">
              Choose from question bank categories
            </h2>
            {/*
             */}
            <AddSetsForm
              type={homework ? "homework" : exam ? "exam" : "quiz"}
              id={
                homework
                  ? getInfoSuccess?.data?.homework_info[0].key
                  : getInfoSuccess?.data?.quiz_info[0]?.key
              }
              setRefetch={setRefetch}
              refetch={refetch}
              admin={admin}
            />
          </div>

          {getInfoSuccess?.data?.sets?.length > 0 && (
            <div className="category-questions flex w-full flex-col items-center gap-2 md:items-center ">
              {homework && (
                <>
                  <h2 className="text-center text-xl font-semibold">
                    Data for all collected questions
                  </h2>

                  <SetsTable
                    id={getInfoSuccess?.data?.homework_info[0].key}
                    tableFor={
                      admin ? "homeworkSetsAdmin" : "homeworkSetsTeacher"
                    }
                    admin={admin}
                  />
                </>
              )}
              {!homework && !exam && (
                <>
                  <h2 className="text-center text-3xl font-semibold">
                    Data for all collected questions
                  </h2>

                  <SetsTable
                    id={getInfoSuccess?.data?.quiz_info[0]?.key}
                    tableFor={admin ? "quizSetsAdmin" : "quizSetsTeacher"}
                  />
                </>
              )}

              {exam && (
                <>
                  <h2 className="text-center text-3xl font-semibold">
                    Data for all collected questions{" "}
                  </h2>

                  <SetsTable
                    id={getInfoSuccess?.data?.quiz_info[0].key}
                    tableFor={"examSets"}
                  />
                </>
              )}
            </div>
          )}

          <div className="custom-question  w-full ">
            <h2 className=" text-3xl font-semibold">Add a custom question </h2>
            {/* <div className="custom-question-options mt-14 flex justify-start gap-10 md:justify-center">
              <button
                onClick={() => setQuestionType("choose")}
                className={`  rounded-2xl border-2  border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-light  active:scale-90 ${
                  questionType === "choose" ? "bg-secondary text-light" : ""
                }`}
              >
                Choices question
              </button>
              <button
                onClick={() => setQuestionType("essay")}
                className={`  rounded-2xl border-2 border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-light active:scale-90 ${
                  questionType === "essay" ? "bg-secondary text-light" : ""
                }`}
              >
                Essay question
              </button>
            </div> */}
            {/* {questionType === "choose" ? ( */}
            <AddEditChooseQuestion
              type={homework ? "homework" : exam ? "exam" : "quiz"}
              id={
                homework
                  ? getInfoSuccess?.data?.homework_info[0].key
                  : getInfoSuccess?.data?.quiz_info[0]?.key
              }
              refetch={refetch}
              setRefetch={setRefetch}
              admin={admin}
            />
            {/* // ) : (
            //   <AddEditEssayQuestion
            //     type={homework ? "homework" : exam ? "exam" : "quiz"}
            //     id={
            //       homework
            //         ? getInfoSuccess?.data?.homework_info[0]?.key
            //         : getInfoSuccess?.data?.quiz_info[0]?.key
            //     }
            //     setRefetch={setRefetch}
            //     refetch={refetch}
            //     admin={admin}
            //   />
            // )} */}
          </div>
          {getInfoSuccess?.data?.Questions?.length > 0 && (
            <div className="all-custom-questions w-full ">
              <h2 className=" text-3xl font-semibold"> Specific Questions </h2>
              <QuestionBankCategory
                type={homework ? "homework" : "quiz"}
                id={
                  homework
                    ? getInfoSuccess?.data?.homework_info[0].key
                    : getInfoSuccess?.data?.quiz_info[0]?.key
                }
                QuizQuestionsData={getInfoSuccess?.data?.Questions}
                setRefetchQuiz={setRefetch}
                refetchQuiz={refetch}
                admin={admin}
              />
            </div>
          )}
        </>
      ) : null}
    </section>
  );
}

export default AdminQuiz;
